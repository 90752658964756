const orderModal = () => {
    const modal = document.querySelector('#modal')
    if (!modal) {
        return
    }

    const openPlatformEls = document.querySelectorAll('[data-id=open-modal-platform]')
    const openFranchize = document.querySelector('#open-modal-franchize')

    // const buttonsBackground = document.querySelector('.modal .switch-theme .background')

    // all links with href="#open-platform"
    const openPlatformLinks = document.querySelectorAll('.open-platform')
    const openFranchizeLinks = document.querySelectorAll('.open-franchize')

    const closeModal = modal.querySelector('.modal__close')
    const shadowModal = modal.querySelector('.modal__shadow')
    const siteHeader = document.querySelector('.site-header')
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    const mobileWidth = 1301

    const handleModal = ({
        darkMode = false,
    }) => {
        modal.classList.add('modal--opened')
        document.body.classList.add('body-locked')
        document.body.style.paddingRight = `${scrollbarWidth}px`
        siteHeader.style.paddingRight = `${scrollbarWidth}px`

        modal.classList.toggle('modal--dark', darkMode)
    }

    // open platform modal
    openPlatformEls.forEach((openPlatform) => {
        openPlatform?.addEventListener('click', () => {
            handleModal({
                darkMode: false,
            })
            updateType('platform')
        })
    })
    openPlatformLinks.forEach((link) => {
        link.addEventListener('click', () => {
            // if is mobile
            if (window.innerWidth < mobileWidth) {
                // scroll to #order
                const order = document.querySelector('#order')
                order.scrollIntoView()
            } else {
                handleModal({
                    darkMode: false,
                })
            }
        })
    })

    // open franchize modal
    openFranchize?.addEventListener('click', () => {
        handleModal({
            darkMode: true,
            primaryButton: true,
        })
        updateType('franchize')
    })
    openFranchizeLinks.forEach((link) => {
        link.addEventListener('click', () => {
            // if is mobile
            if (window.innerWidth < mobileWidth) {
                // scroll to #order
                const order = document.querySelector('#order')
                order.scrollIntoView()
            } else {
                handleModal({
                    darkMode: true,
                    primaryButton: true,
                })
            }
        })
    })

    closeModal?.addEventListener('click', () => {
        clearModalInfo()
    })

    shadowModal?.addEventListener('click', () => {
        clearModalInfo()
    })

    const updateType = (type) => {
        const typeSelector = modal.querySelector('input[name="type"]')
        typeSelector.value = type
    }

    function clearModalInfo() {
        modal.classList.remove('modal--opened')
        document.body.classList.remove('body-locked')
        document.body.style.paddingRight = ''
        siteHeader.style.paddingRight = ''
    }

    const handleSelectDropdowns = () => {
        const selectElements = document.querySelectorAll('.js-select')

        selectElements.forEach((select) => {
            const selectHead = select.querySelector('.select__head')
            const selectList = select.querySelector('.select__list')

            selectHead.addEventListener('click', () => {
                const isOpen = selectHead.classList.contains('open')
                closeAllSelects()
                if (!isOpen) {
                    selectHead.classList.add('open')
                    selectList.classList.add('open')
                }
            })

            selectList.addEventListener('click', (event) => {
                const form = select.closest('form')
                if (event.target.classList.contains('select__item')) {
                    const selectedItem = event.target
                    const selectHeadElement = selectedItem
                        .closest('.js-select')
                        .querySelector('.select__head')

                    document.querySelectorAll('.select__item').forEach((item) => {
                        item.classList.remove('select__item_active')
                    })
                    selectedItem.classList.add('select__item_active')

                    selectHeadElement.classList.remove('open')
                    selectList.classList.remove('open')
                    selectHeadElement.textContent = selectedItem.textContent
                    selectHeadElement.previousElementSibling.value = selectedItem.textContent

                    handleFieldVisibility(selectedItem.dataset.show, form)
                }
            })
        })

        document.addEventListener('click', (event) => {
            if (!event.target.closest('.js-select')) {
                closeAllSelects()
            }
        })

        const closeAllSelects = () => {
            document.querySelectorAll('.select__head.open').forEach((head) => {
                head.classList.remove('open')
            })
            document.querySelectorAll('.select__list.open').forEach((list) => {
                list.classList.remove('open')
            })
        }
    }

    const handleFieldVisibility = (selectedData, form) => {
        const multiFieldWrapper = form.querySelector('.js-multi-field')
        const inputWrappers = multiFieldWrapper.querySelectorAll('.order__input-wrapper')

        inputWrappers.forEach((inputWrapper) => {
            inputWrapper.classList.add('hidden')
            const input = inputWrapper.querySelector('.input')
            input.removeAttribute('required')
            input.value = ''
            const nextEl = input.nextElementSibling
            if (nextEl && nextEl.classList.contains('error-message')) {
                nextEl.remove()
            }
        })

        const targetInputWrapper = multiFieldWrapper.querySelector(`[data-field="${selectedData}"]`)
        if (targetInputWrapper) {
            targetInputWrapper.classList.remove('hidden')
            const targetInput = targetInputWrapper.querySelector('.input')
            targetInput.setAttribute('required', 'required')
        }
    }

    handleSelectDropdowns()
}

export default orderModal
